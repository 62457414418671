const CalculateScoreGained = (home_team_score, out_team_scored, home_team_guessed, out_team_guessed) => {
    let total = 0;
    if(home_team_score < out_team_scored && home_team_guessed < out_team_guessed)
	total += 3;
    else if(home_team_score > out_team_scored && home_team_guessed > out_team_guessed)
	total += 3;
    else if(home_team_score === out_team_scored && home_team_guessed === out_team_guessed)
	total += 3;

    if(home_team_score === home_team_guessed && out_team_scored === out_team_guessed)
	total += 5;

    return total;
}

export default CalculateScoreGained;
import React from "react";
import './Gallery.css';
import FetchData from './FetchData';
import {ImageUrl} from "../ApiUrl";

class Gallery extends React.Component {
    constructor(props) {
	super(props);

	this.state = {
	    hidden: true,
	    images: []
	};
    }
    async componentDidMount() {
	const options = {
	    method: "GET",
	    headers: {"Authorization": "Bearer " + sessionStorage.getItem("token")},
	};

	const data = await FetchData(this.props.type + "/?image_location_only=true", options);
	if(data.isError) {
	    this.props.setErrorMessage(data.message);
	    return;
	}

	const images = ["placeholder.jpg"];
	data.data.forEach(image => {
	    images.push(image[`${this.props.type}_logo_file`]);
	});

	this.setState({
	    images: [...new Set(images)]
	});
    }
    handleClick() {
	this.setState({
	    hidden: !this.state.hidden
	})
    }
    render() {
	const images = this.state.images.map(image => {
	    return (
		<div
		    key={image} 
		    style={{backgroundImage: `url("${ImageUrl}${image}")`}}
		    onClick={() => this.props.changeImage(image)}
		/>
	    );
	});

	return (
	    <div>
		<button
		    onClick={() => this.handleClick()}
		>
		    Galerij
		</button>
		<div className={this.state.hidden ? "Gallery Hidden" : "Gallery"}>
		    {images}
		</div>
	    </div>
	);
    }
}

export default Gallery;
export default function FormatTime(timestamp) {
	const format = {weekday: "long", day: "numeric", month: "long"};
	const date = new Date(timestamp * 1000);

	let hours = date.getHours();
	let minutes = date.getMinutes();
	if(hours < 10)
		hours = "0" + hours;
	if(minutes < 10)
		minutes = "0" + minutes;

	const time = hours + ":" + minutes;
	const output = {date: date.toLocaleString(undefined, format), time: time}
	output.fancy = output.date + " " + output.time;

	return output;
}

export function FormatDate(timestamp) {
	const locale = new Date(timestamp).toLocaleString();
	const dates = locale.split(" ");
	const date = dates[0].split("-");
	if(date[0].length === 1)
		date[0] = `0${date[0]}`;
	if(date[1].length === 1)
		date[1] = `0${date[1]}`;
	date[2] = date[2].replace(',', '');
	return `${date[2]}-${date[1]}-${date[0]}`;
}
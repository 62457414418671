import React from "react";
import './DataMenu.css';
import FetchData from './FetchData';
import SortData from './SortData';
import ConfirmBox from "./ConfirmBox";
import CheckList from "./CheckList";
import Gallery from "./Gallery";
import FormatTime, {FormatDate} from "./FormatTime";
import {ImageUrl} from "../ApiUrl";

function handleSubmit(event) {
	event.preventDefault();
}

function RenderWrapper(props) {
	return (
		<div className={"AddMenu " + props.className}>
			<h1>{props.title}</h1>
			<form onSubmit={handleSubmit.bind(this)}>
				{props.content}
			</form>
			{props.handleSave ? <button onClick={props.handleSave}>Ok</button> : null}
			{props.handleDelete ? <button onClick={props.handleDelete}>Verwijderen</button> : null}
			{props.delete}
		</div>
	);
}

export class AddScores extends React.Component {
	constructor(props) {
		super(props);

		const match = this.props.match;
		this.state = {
			properties: {
				home_team_score: match.home_team_score || 0,
				out_team_score: match.out_team_score || 0,
			}
		};
	}
	handleInput(event, key) {
		const properties = structuredClone(this.state.properties);
		properties[key] = event.target.value;
		this.setState({
			properties: properties
		});
	}
	async handleSave() {
		const properties = structuredClone(this.state.properties);

		const input = {
			home_team_score: properties.home_team_score,
			out_team_score: properties.out_team_score,
		};
		const options = {
			method: "PATCH",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(input)
		}

		const data = await FetchData("match/" + this.props.match.id, options);
		if(data.isError)
			this.props.setErrorMessage(data.message);
		else
			this.props.updateData("matches", data.data[0]);
	}
	render() {
		const content = (
			<div>
				<label>{this.props.match.home_team.name}</label>
				<input
					type="number"
					name="inputHome"
					min="0"
					max="100"
					onChange={(e) => this.handleInput(e, "home_team_score")}
					value={this.state.properties.home_team_score}
					autoFocus
				/>
				<label>{this.props.match.out_team.name}</label>
				<input
					type="number"
					name="inputOut"
					min="0"
					max="100"
					onChange={(e) => this.handleInput(e, "out_team_score")}
					value={this.state.properties.out_team_score}
				/>
			</div>
		);

		return (
			<RenderWrapper
				className="Scores"
				title="Eindstand"
				content={content}
				handleSave={this.handleSave.bind(this)}
			/>
		);
	}
}

export class Menu extends React.Component {
	handleInput(event, key, isNumber = false) {
		const properties = structuredClone(this.state.properties);
		let value = event.target.value;
		if(isNumber)
			value = +value;

		properties[key] = value;
		this.setState({
			properties: properties
		});
	}
	handleCheckList(event, key, value) {
		const properties = structuredClone(this.state.properties);
		let array = properties[key];
		if(array === undefined)
			array = [];

		if(!array.includes(value) && event.target.checked)
			array.push(value);
		else if(array.includes(value) && !event.target.checked)
			array.splice(array.indexOf(value), 1);

		properties[key] = array;
		this.setState({
			properties: properties
		});
	}
	handleImage(event) {
		const properties = structuredClone(this.state.properties);
		const file = event.target.files[0];
		if(file === undefined)
			return;

		properties.file = file;
		properties.logo_file = undefined;
		this.setState({
			properties: properties
		});
	}
	handleDelete() {
		this.setState({
			delete: (<ConfirmBox name={this.deleteName} confirmResult={this.confirmResult.bind(this)} />)
		});
	}
	async confirmResult(result) {
		this.setState({
			delete: null
		});

		if(result) {
			const options = {
				method: "DELETE"
			}

			const data = await FetchData(`${this.deleteItem}/${this.props[this.deleteItem].id}`, options);
			if(data.isError)
				this.props.setErrorMessage(data.message);
			else
				this.props.deleteData(this.deleteData, this.props[this.deleteItem]);
		}
	}
}

export class MenuMatch extends Menu {
	constructor(props) {
		super(props);

		const match = this.props.match;

		let teamHome = 0;
		let teamOut = 0;
		let date;
		let time

		if(!match) {
			date = FormatDate(Date.now())
			time = "";
		}
		else {
			teamHome = match.home_team.id;
			teamOut = match.out_team.id;

			date = FormatDate(match.start_datetime * 1000);
			time = FormatTime(match.start_datetime);
			time = time.time;
		}

		this.times = [
			"00:00",
			"11:00",
			"12:15",
			"14:00",
			"14:30",
			"15:00",
			"15:30",
			"16:00",
			"16:30",
			"16:45",
			"17:00",
			"18:00",
			"18:45",
			"19:30",
			"20:00",
			"20:30",
			"20:45",
			"21:00",
		]

		const properties = {
			competitions: [],
			home_team: teamHome,
			out_team: teamOut,
			date: date,
			time: time,
		};

		if(match) {
			properties.competitions = match.competitions.map(c => {
				return c.id;
			});
		}

		this.deleteName = "wedstrijd";
		this.deleteItem = "match";
		this.deleteData = "matches";

		this.state = {
			properties: properties,
			delete: null,
			teams: []
		};
	}
	componentDidMount() {
		if(this.props.match) {
			this.setState({
				teams: this.getTeams()
			});
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const prevProperies = prevState.properties;
		const properties = structuredClone(this.state.properties);

		if(prevProperies.competitions.length === properties.competitions.length)
			return;

		const teams = this.getTeams();
		if(teams.length === this.state.teams.length)
			return;

		properties.home_team = 0;
		properties.out_team = 0;

		this.setState({
			teams: teams,
			properties: properties
		});
	}
	getTeams() {
		const competitions = this.props.competitions;
		const selectedCompetitions = [];
		competitions.forEach(competition => {
			const selectedIds = JSON.parse(JSON.stringify(this.state.properties.competitions));
			for(let i = 0; i < selectedIds.length; i++) {
				if(selectedIds[i] !== competition.id)
					continue;

				selectedCompetitions.push(competition);
				selectedIds.splice(i, 1);
				break;
			}
		});

		const teams = [];
		selectedCompetitions.forEach(competition => {
			competition.teams.forEach(team => {
				if(teams.filter(t => t.id === team.id).length === 0)
					teams.push(team);
			});
		});

		if(teams.length > 0)
			teams.push({id: 0, name: ""});

		return SortData(teams, "name");
	}
	async handleSave() {
		const properties = structuredClone(this.state.properties);

		if(!properties.competitions) {
			this.props.setErrorMessage("Geen competitie geselecteerd.");
			return;
		}

		if(!+properties.home_team || !+properties.out_team) {
			this.props.setErrorMessage("Geen team geselecteerd.");
			return;
		}

		if(!properties.date || !properties.time) {
			this.props.setErrorMessage("Geen tijd geselecteerd.");
			return;
		}

		let time = "";
		if(properties.time.includes("."))
			time = properties.time.split(".");
		else
			time = properties.time.split(":");

		if(time.length !== 2) {
			this.props.setErrorMessage("Tijd is incorrect ingevoerd.");
			return;
		}

		let hour = parseInt(time[0]);
		const minute = parseInt(time[1]);
		if(isNaN(hour) || isNaN(minute)) {
			this.props.setErrorMessage("Tijd is incorrect ingevoerd.");
			return;
		}

		if(hour > 23 || minute > 59) {
			this.props.setErrorMessage("Tijd is incorrect ingevoerd.");
			return;
		}

		if(time[0].length < 2)
			time[0] = "0" + time[0];
		else if(time[0].length > 2) {
			this.props.setErrorMessage("Tijd is incorrect ingevoerd.");
			return;
		}

		if(time[1].length !== 2) {
			this.props.setErrorMessage("Tijd is incorrect ingevoerd.");
			return;
		}

		const timestamp = new Date(properties.date + "T" + time[0] + ":" + time[1]).getTime() / 1000;

		const input = {
			competition_ids: properties.competitions,
			home_team_id: properties.home_team,
			out_team_id: properties.out_team,
			start_datetime: timestamp
		};

		let data
		if(!this.props.match) {
			const options = {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(input)
			};
			data = await FetchData("match", options);
		}
		else {
			const options = {
				method: "PATCH",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(input)
			};
			data = await FetchData("match/" + this.props.match.id, options);
		}

		if(data.isError)
			this.props.setErrorMessage(data.message);
		else
			this.props.updateData("matches", data.data[0]);

	}
	render() {
		const teamsDropdown = this.state.teams.map(team =>
			<option
				key={team.id}
				value={team.id}
			>
				{team.name}
			</option>
		);

		const times = [];
		this.times.forEach((time) => {
			times.push(
				<option
					key={time}
					value={time}
				>
					{time}
				</option>
			);
		});

		const content = (
			<div>
				<label>Competities</label><br />
				<CheckList
					type={"competitions"}
					list={this.props.competitions}
					checked={this.state.properties.competitions}
					onChange={this.handleCheckList.bind(this)}
				/>
				<label>Teams</label><br />
				<select
					name="home_team"
					onChange={(e) => this.handleInput(e, "home_team")}
					value={this.state.properties.home_team}
				>
					{teamsDropdown}
				</select>
				<select
					name="out_team"
					onChange={(e) => this.handleInput(e, "out_team")}
					value={this.state.properties.out_team}
				>
					{teamsDropdown}
				</select><br />
				<input
					type="date"
					name="date"
					onChange={(e) => this.handleInput(e, "date")}
					value={this.state.properties.date}
				>
				</input>
				<input
					name="time"
					onChange={(e) => this.handleInput(e, "time")}
					value={this.state.properties.time}
					list="list-time"
				>
				</input>
				<datalist id="list-time">
					{times}
				</datalist>
			</div>
		);

		return (
			<RenderWrapper
				className="Match"
				title={this.props.match ? `${this.props.match.home_team.name} - ${this.props.match.out_team.name}` : "Wedstrijd toevoegen"}
				content={content}
				handleSave={this.handleSave.bind(this)}
				handleDelete={this.props.match ? this.handleDelete.bind(this) : null}
				delete={this.state.delete}
			/>
		);
	}
}

export class MenuCompetition extends Menu {
	constructor(props) {
		super(props);

		let name;
		let teams;
		if(!this.props.competition) {
			name = "";
			teams = [];
		}
		else {
			name = this.props.competition.name;
			teams = this.props.competition.teams.map(t => {
				return t.id
			})
		}

		this.deleteName = "competitie";
		this.deleteItem = "competition";
		this.deleteData = "competitions";

		this.state = {
			properties: {
				name: name,
				teams: teams
			},
			inputKey: undefined
		};
	}
	changeImage(image) {
		const properties = structuredClone(this.state.properties);
		properties.file = undefined;
		properties.logo_file = image;

		this.setState({
			properties: properties,
			inputKey: Date.now()
		});
	}
	async handleSave() {
		const properties = structuredClone(this.state.properties);

		if(properties.name.length === 0) {
			this.props.setErrorMessage("Naam is vereist.");
			return;
		}

		const input = {
			name: properties.name,
			season_id: this.props.season.id,
			team_ids: properties.teams
		};

		const formData = new FormData();
		if(properties.file)
			formData.append("image", properties.file);
		else if(properties.logo_file)
			input.competition_logo_file = properties.logo_file;

		formData.append("competition", JSON.stringify(input));

		let data;
		if(!this.props.competition) {
			const options = {
				method: "POST",
				body: formData
			};
			data = await FetchData("competition", options);
		}
		else {
			const options = {
				method: "PATCH",
				body: formData
			};
			data = await FetchData("competition/" + this.props.competition.id, options);
		}

		if(data.isError)
			this.props.setErrorMessage(data.message);
		else
			this.props.updateData("competitions", data.data[0]);
	}
	render() {
		let imageSrc = null;
		if(this.state.properties.logo_file)
			imageSrc = this.state.properties.logo_file;
		else if(this.props.competition)
			imageSrc = this.props.competition.competition_logo_file;

		const style = {};
		if(imageSrc)
			style.backgroundImage = `url("${ImageUrl}${imageSrc}")`;

		const content = (
			<div>
				<label>Naam</label><br />
				<input
					type="text"
					name="name"
					onChange={(e) => this.handleInput(e, "name")}
					value={this.state.properties.name}
					autoFocus
					required
				/><br />
				<label>Afbeelding</label><br />
				<div
					className="Image"
					style={style}
				/>
				<input
					type="file"
					accept="image/*"
					key={this.state.inputKey || ""}
					onChange={(e) => this.handleImage(e)}
				/>
				<br />
				<Gallery
					type={this.deleteItem}
					changeImage={this.changeImage.bind(this)}
				/><br />
				<CheckList
					type={"teams"}
					list={this.props.teams}
					checked={this.state.properties.teams}
					onChange={this.handleCheckList.bind(this)}
				/>
			</div>
		);

		return (
			<RenderWrapper
				className="Competition"
				title={this.props.competition ? this.props.competition.name : "Competitie toevoegen"}
				content={content}
				handleSave={this.handleSave.bind(this)}
				handleDelete={this.props.competition ? this.handleDelete.bind(this) : null}
				delete={this.state.delete}
			/>
		);
	}
}

export class MenuCodes extends Menu {
	constructor(props) {
		super(props);

		this.state = {
			newCode: {
				oneTime: false,
				expires: Date.now()
			},
			deleteCode: null
		}
	}
	handleOneTime(event) {
		const newCode = this.state.newCode;
		newCode.oneTime = event.target.checked;
		this.setState({
			newCode: newCode
		});
	}
	handleExpire(event) {
		const newCode = this.state.newCode;
		if(event.target.checked) {
			newCode.expires = 0;
			this.setState({
				newCode: newCode
			});
		}
		else {
			newCode.expires = Date.now();
			this.setState({
				newCode: newCode
			});
		}
	}
	handleDate(event) {
		const newCode = this.state.newCode;
		const value = event.target.value;
		if(value.length === 0) {
			newCode.expires = Date.now();
			this.setState({
				newCode: newCode
			});
		}
		else {
			const timestamp = new Date(event.target.value + "T23:59:59").getTime();
			newCode.expires = timestamp;
			this.setState({
				newCode: newCode
			});
		}
	}
	async handleGenerate() {
		let expires = this.state.newCode.expires;
		if(expires > 0) {
			const date = new Date(this.state.newCode.expires).toLocaleString();
			const dates = date.split(" ");
			const time = dates[1].split(":");

			const subtract = ((time[0] * 60 * 60) + (time[1] * 60) + (time[2] * 1)) * 1000;
			expires -= subtract;
			expires = Math.floor(expires / 1000);
			expires += (24 * 60 * 60) - 1;
		}

		const input = {
			competition_id: this.props.competition.id,
			expire_on_use: this.state.newCode.oneTime
		};

		if(expires > 0)
			input.expire_datetime = expires;

		const options = {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(input)
		};

		const data = await FetchData("competitioncode", options);
		if(data.isError)
			this.props.setErrorMessage(data.message);
		else {
			this.props.updateData("codes", data.data[0]);
			this.setState({
				newCode: {
					oneTime: false,
					expires: Date.now()
				}
			});
		}
	}
	handleDeleteCode(code) {
		this.setState({
			delete: (<ConfirmBox name="code" confirmResult={this.confirmResultCode.bind(this)} />),
			deleteCode: code
		});
	}
	async confirmResultCode(result) {
		this.setState({
			delete: null
		});

		if(result) {
			const options = {
				method: "DELETE"
			}

			const data = await FetchData(`competitioncode/?id=${this.state.deleteCode.id}&delete_expired=false`, options);
			if(data.isError)
				this.props.setErrorMessage(data.message);
			else
				this.props.deleteData("codes", this.state.deleteCode);
		}
	}
	render() {
		const codes = this.props.codes;
		const content = [];
		if(codes) {
			for(let i = 0; i < codes.length; i++) {
				const code = codes[i];
				let time = "Nooit";
				if(code.expire_datetime) {
					time = FormatTime(code.expire_datetime);
					time = time.fancy;
				}

				const oneTime = code.expire_on_use ? "Ja" : "Nee";

				content.push(
					<div key={code.code}>
						<span className="codeLabel">{code.code}</span> {`Verloopt: ${time} Eenmalig: ${oneTime}`}
						<br />
						<button onClick={() => this.handleDeleteCode(code)}>Code verwijderen</button>
						<hr />
					</div>
				);
			}
		}

		const disabled = this.state.newCode.expires === 0;
		const date = FormatDate(disabled ? Date.now() : this.state.newCode.expires);
		const min = FormatDate(Date.now());

		content.push(
			<div key="new">
				Nieuwe code
				<label>
					<input
						type="checkbox"
						value="oneTime"
						checked={this.state.newCode.oneTime}
						onChange={(e) => this.handleOneTime(e)}
					/>
					Eenmalig
				</label>
				<label>
					<input
						type="checkbox"
						value="expires"
						checked={this.state.newCode.expires === 0}
						onChange={(e) => this.handleExpire(e)}
					/>
					Altijd geldig
				</label>
				Verloopt op:
				<input
					type="date"
					name="date"
					disabled={disabled}
					min={min}
					value={date}
					onChange={(e) => this.handleDate(e)}
				>
				</input><br />
				<button onClick={() => this.handleGenerate()}>Code genereren</button>
			</div>
		);

		return (
			<RenderWrapper
				className="Codes"
				title="Codes"
				content={content}
				delete={this.state.delete}
			/>
		);
	}
}

export class MenuSeason extends Menu {
	constructor(props) {
		super(props);

		const season = this.props.season;
		let name;

		if(!season)
			name = "";
		else
			name = season.name;

		this.deleteName = "seizoen";
		this.deleteItem = "season";
		this.deleteData = "seasons";

		this.state = {
			properties: {
				name: name
			}
		};
	}
	async handleSave() {
		const properties = structuredClone(this.state.properties);

		if(properties.name.length === 0) {
			this.props.setErrorMessage("Naam is vereist.");
			return;
		}

		const input = {
			name: properties.name
		};

		let data;
		if(!this.props.season) {
			const options = {
				method: "POST",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(input)
			};
			data = await FetchData("season", options);
		}
		else {
			const options = {
				method: "PATCH",
				headers: {"Content-Type": "application/json"},
				body: JSON.stringify(input)
			};
			data = await FetchData("season/" + this.props.season.id, options);
		}

		if(data.isError)
			this.props.setErrorMessage(data.message);
		else
			this.props.updateData("seasons", data.data[0]);
	}
	render() {
		let button = [];
		if(this.props.season) {
			button.push(
				<button
					key={0}
					onClick={() => this.props.selectSeason(this.props.season)}
				>
					Seizoen selecteren
				</button>
			);
			button.push(<br key={1} />);
		}
		const content = (
			<div>
				{button}
				<label>Naam</label><br />
				<input
					type="text"
					name="name"
					onChange={(e) => this.handleInput(e, "name")}
					value={this.state.properties.name}
					autoFocus
					required
				/>
			</div>
		);

		return (
			<RenderWrapper
				className="Season"
				title={this.props.season ? this.props.season.name : "Seizoen toevoegen"}
				content={content}
				handleSave={this.handleSave.bind(this)}
				handleDelete={this.props.season ? this.handleDelete.bind(this) : null}
				delete={this.state.delete}
			/>
		);
	}
}

export class MenuTeam extends Menu {
	constructor(props) {
		super(props);

		const team = this.props.team;

		let name;
		let competitions;

		if(!team) {
			name = "";
			competitions = [];
		}
		else {
			name = team.name;
			competitions = team.competitions.map(c => {
				return c.id;
			});
		}

		this.deleteName = "team";
		this.deleteItem = "team";
		this.deleteData = "teams";

		this.state = {
			properties: {
				name: name,
				file: null,
				competitions: competitions
			}
		};
	}
	changeImage(image) {
		const properties = structuredClone(this.state.properties);
		properties.file = undefined;
		properties.logo_file = image;

		this.setState({
			properties: properties,
			inputKey: Date.now()
		});
	}
	async handleSave() {
		const properties = structuredClone(this.state.properties);

		console.log(properties.competitions);

		if(properties.name.length === 0) {
			this.props.setErrorMessage("Naam is vereist.");
			return;
		}

		if(this.props.teams.filter(x => x.name.toLowerCase() === properties.name.toLowerCase()).length > 0) {
			this.props.setErrorMessage("Team bestaat al.")
			return;
		}

		if(!properties.competitions.length) {
			this.props.setErrorMessage("Geen competitie geselecteerd.");
			return;
		}

		const input = {
			name: properties.name,
			competition_ids: properties.competitions
		};

		const formData = new FormData();
		if(properties.file)
			formData.append("image", properties.file);
		else if(properties.logo_file)
			input.team_logo_file = properties.logo_file;

		formData.append("team", JSON.stringify(input));

		let data;
		if(!this.props.team) {
			const options = {
				method: "POST",
				body: formData
			};
			data = await FetchData("team", options);
		}
		else {
			const options = {
				method: "PATCH",
				body: formData
			};
			data = await FetchData("team/" + this.props.team.id, options);
		}

		if(data.isError)
			this.props.setErrorMessage(data.message);
		else
			this.props.updateData("teams", data.data[0]);

	}
	render() {
		let imageSrc = null;
		if(this.state.properties.logo_file)
			imageSrc = this.state.properties.logo_file;
		else if(this.props.team)
			imageSrc = this.props.team.team_logo_file;

		const style = {};
		if(imageSrc)
			style.backgroundImage = `url("${ImageUrl}${imageSrc}")`;

		const content = (
			<div>
				<label>Naam</label><br />
				<input
					type="text"
					name="name"
					onChange={(e) => this.handleInput(e, "name")}
					value={this.state.properties.name}
					autoFocus
					required
				/><br />
				<label>Competities</label><br />
				<CheckList
					type={"competitions"}
					list={this.props.competitions}
					checked={this.state.properties.competitions}
					onChange={this.handleCheckList.bind(this)}
				/><br />
				<label>Afbeelding</label><br />
				<div
					className="Image"
					style={style}
				/>
				<input
					type="file"
					accept="image/*"
					key={this.state.inputKey || ""}
					onChange={(e) => this.handleImage(e)}
				/>
				<br />
				<Gallery
					type={this.deleteItem}
					changeImage={this.changeImage.bind(this)}
				/>
			</div>
		);

		return (
			<RenderWrapper
				className="Team"
				title={this.props.team ? this.props.team.name : "Team toevoegen"}
				content={content}
				handleSave={this.handleSave.bind(this)}
				handleDelete={this.props.team ? this.handleDelete.bind(this) : null}
				delete={this.state.delete}
			/>
		);
	}
}

export class MenuPlayer extends Menu {
	constructor(props) {
		super(props);

		const player = this.props.user;
		const competitions = player.competitions.map(c => {
			return c.id;
		});

		this.deleteName = "speler";
		this.deleteItem = "user";
		this.deleteData = "players";

		this.state = {
			properties: {
				competitions: competitions
			}
		};
	}
	async handleSave() {
		const properties = structuredClone(this.state.properties);

		const input = {
			competition_ids: properties.competitions
		};

		const options = {
			method: "PATCH",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(input)
		};

		const data = await FetchData("user/" + this.props.user.id, options);

		if(data.isError)
			this.props.setErrorMessage(data.message);
		else
			this.props.updateData("players", data.data[0]);
	}
	render() {
		const player = this.props.user;
		const content = (
			<div>
				<label>{`Naam: ${player.name}`}</label><br />
				<label>{`Email: ${player.email}`}</label><br />
				<label>Competities</label><br />
				<CheckList
					type={"competitions"}
					list={this.props.competitions}
					checked={this.state.properties.competitions}
					onChange={this.handleCheckList.bind(this)}
				/>
			</div>
		);

		return (
			<RenderWrapper
				className="Player"
				title="Speler"
				content={content}
				handleSave={this.handleSave.bind(this)}
				handleDelete={this.handleDelete.bind(this)}
				delete={this.state.delete}
			/>
		);
	}
}
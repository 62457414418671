import ApiUrl from "../ApiUrl";

export default async function FetchData(url, options = {}) {
	if(!url.includes("logout"))
		await RefreshToken();

	return await Fetch(url, options);
}

function Fetch(url, options) {
	if(options.headers === undefined)
		options.headers = {};

	options.headers.Authorization = "Bearer " + sessionStorage.getItem("token");
	return fetch(ApiUrl + url, options)
		.then(response => response.json())
		.then(response => {
			if(response.code !== 200 && response.access_token === undefined)
				response.isError = true;

			if(response.code === 401) {
				if(url === 'user/a_me' || url === 'userapi/user/token' || url.startsWith('userapi/user/refresh_token'))
					return response;

				sessionStorage.removeItem("token");
				sessionStorage.removeItem("refresh");
				sessionStorage.removeItem("time");
				sessionStorage.setItem("isAdmin", false);
				response.isError = true;
				response.message = 'Sessie is verlopen';
			}

			return response;
		})
		.catch(error => {
			console.log(error);
			error.isError = true;
			return error;
		})
}

async function RefreshToken() {
	const time = sessionStorage.getItem("time");
	if(time === null)
		return;

	const refresh = sessionStorage.getItem("refresh");
	if(refresh === null)
		return;

	if(Date.now() - time >= (5 * 60 * 1000)) {
		const header = {
			method: "POST"
		};
		const result = await Fetch(`userapi/user/refresh_token?refresh_token=${sessionStorage.getItem("refresh")}`, header);
		if(!result.isError) {
			sessionStorage.setItem("token", result.access_token);
			sessionStorage.setItem("time", Date.now());
		}
		return !result.isError;
	}
	return true;
}
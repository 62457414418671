import React from "react";
import PopUp from "./PopUp";

class ConfirmBox extends React.Component {
    render() {
	const content = (
	    <div>
		<h1>Verwijderen</h1><br />
		{"Weet je zeker dat je deze " + this.props.name + " wilt verwijderen?"}<br />
		<button onClick={() => this.props.confirmResult(true)}>Ja</button>
		<button onClick={() => this.props.confirmResult(false)}>Nee</button>
	    </div>
	)
	return (
	    <PopUp children={content} />
	);
    }
}

export default ConfirmBox;
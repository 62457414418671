import React from "react";
import './MatchCard.css';
import FetchData from '../components/FetchData';
import FormatTime from '../components/FormatTime';
import {ImageUrl} from "../ApiUrl";

class MatchCard extends React.Component {
	constructor(props) {
		super(props);

		let inputHome = "";
		let inputOut = "";
		if(this.props.match.matchscore) {
			inputHome = this.props.match.matchscore.home_team_score.toString();
			inputOut = this.props.match.matchscore.out_team_score.toString();
		}

		this.state = {
			inputHome: inputHome,
			inputOut: inputOut
		};
	}
	updateInput(event, isHomeTeam) {
		const value = event.target.value;
		if(isHomeTeam) {
			this.setState({
				inputHome: value
			});
		}
		else {
			this.setState({
				inputOut: value
			});
		}
	}
	async handleSave() {
		if(this.state.inputHome.length === 0 || this.state.inputOut.length === 0)
			return;

		const inputHome = +this.state.inputHome;
		const inputOut = +this.state.inputOut;

		const input = {
			home_team_score: inputHome,
			out_team_score: inputOut,
			competition_id: this.props.competition.id,
			match_id: this.props.match.id
		};

		const options = {
			headers: {
				"Authorization": "Bearer " + sessionStorage.getItem("token"),
				"Content-Type": "application/json"
			},
			body: JSON.stringify(input)
		};

		let data;
		if(!this.props.match.matchscore) {
			options.method = "POST";
			data = await FetchData("userapi/matchscore", options);
		}
		else {
			options.method = "PATCH";
			data = await FetchData("userapi/matchscore/" + this.props.match.matchscore.id, options);
		}

		if(data.isError)
			this.props.setErrorMessage(data.message);
		else
			this.props.updateMatchscore(this.props.match, data.data[0]);
	}
	renderResult() {
		const homeTeamScore = this.props.match.home_team_score;
		const outTeamScore = this.props.match.out_team_score;
		let result = "?";
		if(homeTeamScore !== null || outTeamScore !== null)
			result = `${this.props.match.home_team_score} - ${this.props.match.out_team_score}`;

		return (
			<div className="Result">
				<p>{result}</p>
			</div>
		);
	}
	renderCompetitionCard() {
		if(!this.props.showCompetition)
			return null;

		return (
			<div className="Competition">
				<a href={`${process.env.PUBLIC_URL}/competition/${this.props.competition.id}`}>
					<img
						src={`${ImageUrl}${this.props.competition.competition_logo_file}`}
						alt={this.props.competition.name}
					/>
					<p>{this.props.competition.name.toUpperCase()}</p>
				</a>
			</div>
		);
	}
	render() {
		let showSave = false;
		if(this.state.inputHome.length > 0 && this.state.inputOut.length > 0) {
			if(this.props.match.matchscore) {
				if(+this.state.inputHome !== this.props.match.matchscore.home_team_score || +this.state.inputOut !== this.props.match.matchscore.out_team_score)
					showSave = true;
			}
			else
				showSave = true;
		}

		const locked = this.props.match.start_datetime <= Math.floor(Date.now() / 1000);
		const playing = this.props.match.home_team_score == null && locked;

		let className = "Card";
		if(playing)
			className += " Playing";
		else if(locked)
			className += " Gray";

		return (
			<div className="MatchCard">
				<p>{FormatTime(this.props.match.start_datetime).fancy}</p>
				<div className="Container">
					<div>
						<div className={className}>
							<div className="Wrapper Home">
								<div style={{backgroundImage: `url("${ImageUrl}${this.props.match.home_team.team_logo_file}")`}}>
								</div>
								<p>{this.props.match.home_team.name.toUpperCase()}</p>
							</div>
							<div className="Wrapper Out">
								<div style={{backgroundImage: `url("${ImageUrl}${this.props.match.out_team.team_logo_file}")`}}>
								</div>
								<p>{this.props.match.out_team.name.toUpperCase()}</p>
							</div>
							<div className="Line">
								<div className="Background Home">
									<input
										type="number"
										name="inputHome"
										min="0"
										max="100"
										placeholder={locked ? null : "0"}
										onChange={e => this.updateInput(e, true)}
										value={this.state.inputHome}
										disabled={locked}
									/>
								</div>
								<div className="Background Out">
									<input
										type="number"
										name="inputOut"
										min="0"
										max="100"
										placeholder={locked ? null : "0"}
										onChange={e => this.updateInput(e, false)}
										value={this.state.inputOut}
										disabled={locked}
									/>
								</div>
								<div className={this.props.match.matchscore ? "Checkmark Done" : "Checkmark"}>
								</div>
							</div>
							<button
								className={showSave && !locked ? null : "Hidden"}
								onClick={() => this.handleSave()}
							>
								Opslaan
							</button>
						</div>
						{locked ? this.renderResult() : null}
					</div>
					{this.renderCompetitionCard()}
				</div>
				<h2>Scores medespelers</h2>
			</div>
		);
	}
}

export default MatchCard;
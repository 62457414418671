import './PopUp.css';

function PopUp(props) {
    return (
	<div className="PopUp-background">
	    <div className="PopUp" onClick={props.onClick}>
		{props.children}
	    </div>
	</div>
    );
}

export default PopUp;
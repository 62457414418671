import './CheckList.css';

function CheckList(props) {
    const checked = props.checked || [];
    const checkList = [];
    props.list.map(item =>
	checkList.push(
	    <label key={item.id}>
		<input
		    type="checkbox"
		    value={item.id}
		    key={item.id}
		    checked={checked.includes(item.id)}
		    onChange={(e, k, v) => props.onChange(e, props.type, item.id)}
		/>
		{item.name}
	    </label>
	)
    );

    return (
	<div className="CheckList">
	    {checkList}
	</div>
    );
}

export default CheckList;
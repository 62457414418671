import './App.css';
import React from "react";
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/user/HomePage';
import CompetitionPage from './pages/user/CompetitionPage';
import CompetitionsListPage from './pages/user/CompetitionsListPage';
import AdminPage from './pages/admin/AdminPage';
import PopUp from './components/PopUp';
import Banner from './components/Banner';
import NavBar from './components/NavBar';

function App() {
    const [errorMessage, setErrorMessage] = React.useState(null);

    const location = useLocation();
    const sessionToken = sessionStorage.getItem("token");
    if(!sessionToken && location.pathname !== "/login" && location.pathname !== "/register")
	return <Navigate to="/login" replace={true} />
    else if(sessionToken && (location.pathname === "/login" || location.pathname === "/register"))
	return <Navigate to="/" replace={true} />
    else if(sessionToken && location.pathname.startsWith("/admin")) {
	if(!JSON.parse(sessionStorage.getItem("isAdmin")))
	    return <Navigate to="/" replace={true} />
    }

    const popUp = (
	<div className="ErrorMessage">
	    <h2>Error</h2>
	    <p>{errorMessage}</p>
	    <button onClick={() => setErrorMessage(null)}>Ok</button>
	</div>
    );

    let className = "";
    if(location.pathname.startsWith("/admin"))
	className += "Admin";
    else
	className += "User";
	

    return (
	<div className="App">
	    {errorMessage ? <PopUp children={popUp} /> : null}
	    {!location.pathname.startsWith("/admin") ? <Banner /> : null}
	    <NavBar location={location}/>
	    <div className={className}>
		<Routes>
		    <Route path="/login" element={<LoginPage setErrorMessage={setErrorMessage} />} />
		    <Route path="/logout" element={<LogoutPage/>}/>
		    <Route path="/register" element={<RegisterPage setErrorMessage={setErrorMessage} />}/>
		    <Route path="/" element={<HomePage setErrorMessage={setErrorMessage} />} />
		    <Route path="/competition/*" element={<CompetitionPage setErrorMessage={setErrorMessage} location={location.pathname} />} />
		    <Route path="/competitions" element={<CompetitionsListPage setErrorMessage={setErrorMessage} />} />
		    <Route path="/admin" element={<AdminPage setErrorMessage={setErrorMessage} />} />
		</Routes>
	    </div>
	</div>
    );
}

export default App;

import React from "react";
import './HomePage.css';
import FetchData from '../../components/FetchData';
import SortData from '../../components/SortData';
import MatchCard from '../../components/MatchCard';

class HomePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			inputHome: new Map(),
			inputOut: new Map(),
			matches: [],
			competitions: [],
			user: null,
			code: ""
		};
	}
	async componentDidMount() {
		const options = {
			method: "GET",
			headers: {"Authorization": "Bearer " + sessionStorage.getItem("token")},
		};
		let data = await FetchData("userapi/user/me", options);
		if(data.isError) {
			this.props.setErrorMessage(data.message);
		}
		else {
			const user = data.data[0];
			this.setState({
				user: user
			});

			const matches = [];
			const competitions = [];

			const userCompetitions = SortData(data.data[0].competitions, "name");
			for(const competition of userCompetitions) {
				data = await FetchData("userapi/competition/" + competition.id, options);
				if(data.isError) {
					this.props.setErrorMessage(data.message);
					continue;
				}
				else {
					const id = data.data[0].id;
					const scoresData = await FetchData(`userapi/matchscore/?from_competition=${id}&user_only=true`, options);
					const scoresMap = new Map();

					if(scoresData.isError && scoresData.code !== 404)
						this.props.setErrorMessage(scoresData.message);
					else if(scoresData.code !== 404) {
						scoresData.data.forEach(score => {
							const matchScore = {
								id: score.id,
								home_team_score: score.home_team_score,
								out_team_score: score.out_team_score
							};

							scoresMap.set(score.match.id, matchScore);
						});
					}

					let competition = data.data[0];
					competitions.push(competition);
					data.data[0].matches.forEach(match => {
						if(match.start_datetime + (24 * 60 * 60) > Math.floor(Date.now() / 1000)) {
							match.competition = id;

							let team = match.home_team.id;
							match.home_team = competition.teams.filter(t => t.id === team)[0];
							team = match.out_team.id;
							match.out_team = competition.teams.filter(t => t.id === team)[0];

							match.matchscore = scoresMap.get(match.id);
							matches.push(match);
						}
					});
				}
			};

			this.setState({
				matches: SortData(matches, "start_datetime"),
				competitions: competitions
			});
		}
	}
	render() {
		const matches = this.state.matches;
		const matchList = matches.map(match => {
			if(!match.home_team || !match.out_team)
				return null;

			const competitions = this.state.competitions;
			const index = this.state.competitions.findIndex(competition => competition.id === match.competition);
			const competition = competitions[index];

			return (
				<MatchCard
					match={match}
					competition={competition}
					key={`${match.id} ${match.competition}`}
					showCompetition={true}
					updateMatchscore={this.updateMatchscore.bind(this)}
				/>
			);
		});

		if(matchList.length === 0) {
			matchList.push(
				<div key="empty">
					<p>
						Geen wedstrijden gevonden
					</p>
				</div>
			);
		}

		return (
			<div className="HomePage">
				{matchList}
			</div>
		)
	}
	updateMatchscore(match, data) {
		const matches = this.state.matches;
		const index = matches.findIndex(m => m.id === match.id && m.competition === match.competition);
		match.matchscore = data
		matches[index] = match;

		this.setState({
			matches: matches
		});
	}
}

export default HomePage;
import React from "react";
import './CompetitionsListPage.css';
import FetchData from '../../components/FetchData';
import {ImageUrl} from "../../ApiUrl";
import SortData from '../../components/SortData';

class CompetitionsListPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: [],
			competitions: [],
			code: ""
		};
	}
	async componentDidMount() {
		const options = {
			method: "GET",
			headers: {"Authorization": "Bearer " + sessionStorage.getItem("token")},
		};

		const data = await FetchData("userapi/user/me", options);
		if(data.isError) {
			this.props.setErrorMessage(data.message);
		}
		else {
			const user = data.data[0];
			const competitions = [];
			for(const competition of user.competitions) {
				const result = await FetchData(`userapi/competition/${competition.id}`, options);
				if(result.isError) {
					this.props.setErrorMessage(result.message);
					return;
				}

				competitions.push(result.data[0]);
			}

			this.setState({
				user: user,
				competitions: SortData(competitions, 'id', true)
			});
		}
	}
	handleCode(event) {
		const value = event.target.value;
		this.setState({
			code: value
		});
	}
	async handleEnterCode() {
		if(this.state.code.length === 0)
			return;

		const options = {
			method: "PATCH",
			headers: {
				"Authorization": "Bearer " + sessionStorage.getItem("token")
			}
		};

		const data = await FetchData("userapi/user/join?code=" + this.state.code, options);
		if(data.isError) {
			if(data.code === 404)
				this.props.setErrorMessage("Code is verlopen of niet geldig");
			this.props.setErrorMessage(data.message);
		}
		else
			this.componentDidMount();
	}
	render() {
		const competitions = this.state.competitions;
		const competitionList = competitions.map(competition => {
			return (
				<div className="Row" key={competition.id}>
					<a
						className="Item"
						href={`../competition/${competition.id}`}
					>
						<div>
							<img
								src={ImageUrl + competition.competition_logo_file}
								alt={competition.name}
							/>
						</div>
						<p>{competition.name.toUpperCase()}</p>
					</a>
				</div>
			);
		});

		return (
			<div className="CompetitionsListPage">
				<h1>Competities</h1>
				<div className="Row">
					<div className="Item Code">
						<input
							type="text"
							maxLength="8"
							placeholder="Code invoeren"
							value={this.state.code}
							onChange={e => this.handleCode(e)}
						></input>
						<button onClick={() => this.handleEnterCode()}>Ok</button>
					</div>
				</div>
				{competitionList}
			</div>
		);
	}
}

export default CompetitionsListPage;
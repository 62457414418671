import React from "react";
import {Navigate} from "react-router-dom";
import FetchData from '../components/FetchData';

function LogoutPage() {
	const options = {
		method: "GET",
		headers: {"Authorization": "Bearer " + sessionStorage.getItem("token")},
	};

	FetchData("userapi/user/logout?refresh_token=" + sessionStorage.getItem("refresh"), options);

	sessionStorage.removeItem("token");
	sessionStorage.removeItem("refresh");
	sessionStorage.removeItem("time");
	sessionStorage.setItem("isAdmin", false);
	return <Navigate to="/login" replace={true} />
}

export default LogoutPage;
import './RegisterPage.css';
import React from "react";
import {Navigate} from "react-router-dom";
import logo from '../resources/ball.png';
import FetchData from '../components/FetchData';

class RegisterPage extends React.Component {
    constructor(props) {
	super(props);

	this.state = {
	    username: "",
	    email: "",
	    password: "",
	    passwordCopy: "",
	    redirect: false
	};
    }
    handleInput(event, type) {
	const value = event.target.value;
	const state = {};
	state[type] = value;

	this.setState(state);
    }
    async handleClick() {
	const username = this.state.username;
	const email = this.state.email;
	const password = this.state.password;
	const passwordCopy = this.state.passwordCopy;

	if(username.length === 0) {
	    this.props.setErrorMessage("Naam is vereist.");
	    return;
	}

	if(email.length === 0) {
	    this.props.setErrorMessage("E-mail is vereist.");
	    return;
	}

	if(password.length === 0 || passwordCopy.length === 0) {
	    this.props.setErrorMessage("Wachtwoord is vereist.");
	    return;
	}

	if(password !== passwordCopy) {
	    this.props.setErrorMessage("Wachtwoorden komen niet overeen.");
	    return;
	}

	let input = {
	    name: username,
	    email: email,
	    hashed_password: password
	};

	let options = {
	    method: "POST",
	    headers: {"Content-Type": "application/json"},
	    body: JSON.stringify(input)
	};

	let data = await FetchData("user", options);
	if(data.isError)
	    this.props.setErrorMessage(data.message);
	else {
	    input = new URLSearchParams();
	    input.append("username", email);
	    input.append("password", password);

	    options = {
		method: "POST",
		headers: {"Content-Type": "application/x-www-form-urlencoded"},
		body: input
	    }

	    data = await FetchData("userapi/user/token", options);
	    if(data.isError)
		this.props.setErrorMessage(data.message);
	    else {
		const token = data.access_token;
		sessionStorage.setItem("token", token);
		sessionStorage.setItem("isAdmin", false);
		this.setState({
		    redirect: true
		});
	    }
	}
    }
    render() {
	if(this.state.redirect)
	    return (<Navigate to="../" replace={true} />);

	return (
	    <div className="App-header">
		<img src={logo} className="App-logo" alt="logo" />
		<p>
		    Registreren
		</p>
		<label>Gebruikersnaam</label>
		<input
		    type="text"
		    id="username"
		    name="username"
		    onChange={(e) => this.handleInput(e, "username")}
		/>
		<label>E-mail</label>
		<input
		    type="text"
		    id="email"
		    name="email"
		    onChange={(e) => this.handleInput(e, "email")}
		/>
		<label>Wachtwoord</label>
		<input
		    type="password"
		    id="password"
		    name="password"
		    onChange={(e) => this.handleInput(e, "password")}
		/>
		<label>Wachtwoord herhalen</label>
		<input
		    type="password"
		    id="passwordCopy"
		    name="passwordCopy"
		    onChange={(e) => this.handleInput(e, "passwordCopy")}
		/>
		<button class="registerButton" onClick={() => this.handleClick()}>Registreer</button>
	    </div>
	);
    }
}

export default RegisterPage;
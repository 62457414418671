import './ButtonAdd.css';

function ButtonAdd(props) {
    return (
	<div className="ButtonAdd NoSelect" onClick={props.onClick}>
	    <p>+</p>
	</div>
    );
}

export default ButtonAdd
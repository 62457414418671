import React from "react";
import './Banner.css';
import logo from '../resources/ball.png';

function Banner() {
    return (
	<div className="Banner">
	    <div>
		<img src={logo} className="App-logo" alt="logo" />
		GB'S VOETBALPOULE
	    </div>
	</div>
    );
}

export default Banner;
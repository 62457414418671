import React from "react";
import './NavBar.css';

function NavBar(props) {
    const adminTab = [];
    if(JSON.parse(sessionStorage.getItem("isAdmin"))) {
	adminTab.push(
	    <a
		href={`${process.env.PUBLIC_URL}/admin`}
		key="admin"
		className={props.location.pathname.startsWith("/admin") ? "Right Current" : "Right"}
	    >
		Admin
	    </a>
	);
    }

    return (
	<div className="NavBar">
	    <div>
		<a
		    href={`${process.env.PUBLIC_URL}/`}
		    className={props.location.pathname === "/" ? "Current" : null}
		>
		    Home
		</a>
		<a
		    href={`${process.env.PUBLIC_URL}/competitions`}
		    className={props.location.pathname.startsWith("/competitions") ? "Current" : null}
		>
		    Competities
		</a>
		{
		    sessionStorage.getItem("token") ?
			<a
			    href={`${process.env.PUBLIC_URL}/logout`}
			    className={props.location.pathname.startsWith("/logout") ? "Right Current" : "Right"}
			>
			    Uitloggen
			</a> :
			<a
			    href={`${process.env.PUBLIC_URL}/login`}
			    className={props.location.pathname.startsWith("/login") ? "Right Current" : "Right"}
			>
			    Inloggen
			</a>
		}
		{adminTab}
	    </div>
	</div>
    );
}

export default NavBar;
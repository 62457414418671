import './LoginPage.css';
import React from "react";
import {Navigate} from "react-router-dom"
import logo from '../resources/ball.png';
import FetchData from '../components/FetchData';

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: "",
			password: "",
			redirect: undefined
		};
	}
	handleInput(event, type) {
		const value = event.target.value;
		const state = this.state;
		state[type] = value
		this.setState(state);
	}
	async handleForm(event) {
		event.preventDefault();

		const input = new URLSearchParams();
		input.append("username", this.state.email);
		input.append("password", this.state.password);

		const options = {
			method: "POST",
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: input
		}

		let data = await FetchData("userapi/user/token", options);
		if(data.isError)
			this.props.setErrorMessage("Error: " + data.message);
		else {
			sessionStorage.setItem("token", data.access_token);
			sessionStorage.setItem("refresh", data.refresh_token);
			sessionStorage.setItem("time", Date.now());
			sessionStorage.setItem("isAdmin", false);

			let redirect = "/";

			const options = {
				method: "GET",
				headers: {"Authorization": "Bearer " + sessionStorage.getItem("token")},
			}
			data = await FetchData("user/a_me", options);
			if(data.error)
				sessionStorage.setItem("isAdmin", false);
			else {
				sessionStorage.setItem("isAdmin", true);
				redirect = "/admin"
			}

			this.setState({
				redirect: redirect
			})
		}
	}
	render() {
		if(this.state.redirect)
			return (<Navigate to={this.state.redirect} />);

		return (
			<div className="App">
				<header className="App-header">
					<img src={logo} className="App-logo" alt="logo" />
					<form
						className="Login"
						onSubmit={e => this.handleForm(e)}
					>
						<p>
							Login
						</p>
						<label>Email</label>
						<input
							type="text"
							id="email"
							name="email"
							autoComplete="on"
							required={true}
							onChange={e => this.handleInput(e, "email")}
							value={this.state.email}
						/>
						<label>Wachtwoord</label>
						<input
							type="password"
							id="password"
							name="password"
							autoComplete="on"
							required={true}
							onChange={e => this.handleInput(e, "password")}
							value={this.state.password}
						/><br />
						<button>Login</button>
					</form>
					<br />
					<a href="./register" className="registerLink">Nieuw account maken</a>
				</header>
			</div>
		);
	}
}

export default LoginPage;
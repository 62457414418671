export default function SortData(array, key, inverse = false) {
    return array.sort((a, b) => Compare(a, b, key, inverse));
}

function Compare(a, b, key, inverse) {
    let x = a[key];
    let y = b[key];

    if(typeof x === "string")
	x = x.toLowerCase();
    if(typeof y === "string")
	y = y.toLowerCase();

    let result = 0;
    if(x < y)
	result = -1;
    else if(x > y)
	result = 1;

    if(inverse)
	result *= -1;

    return result;
}

export function SortData2(array, key1, key2, inverse1 = false, inverse2 = false) {
    return array.sort((a, b) => {
	let result = Compare(a, b, key1, inverse1);
	if(result !== 0)
	    return result;

	return Compare(a, b, key2, inverse2);
    });
}
import React, {createRef} from "react";
import './CollapsibleMenu.css';

class CollapsibleMenu extends React.Component {
	constructor(props) {
		super(props);

		this.ref = createRef(null);

		this.state = {
			height: 0,
			maxHeight: -1,
		};
	}
	componentDidMount() {
		this.setState({
			height: this.ref.current.clientHeight,
			maxHeight: 0
		});
	}
	componentDidUpdate(prevProps) {
		if(prevProps.length !== this.props.length) {
			this.setState({
				maxHeight: -1
			});

			requestAnimationFrame(() => {
				const height = this.ref.current.clientHeight;
				this.setState({
					height: height,
					maxHeight: 0
				});
				requestAnimationFrame(() => {
					this.setState({
						maxHeight: height
					});
				});
			});
		}
	}
	handleClick(event) {
		if((event.target.localName === "input" || event.target.localName === "button") && !event.target.disabled)
			return;

		let maxHeight = 0;
		if(this.state.maxHeight <= 0)
			maxHeight = this.state.height;

		this.setState({
			maxHeight: maxHeight
		});
	}
	render() {
		return (
			<div className="CollapsibleMenu">
				<div
					className="Header"
					onClick={(e) => this.handleClick(e)}
				>
					{this.props.header}
				</div>
				<div
					className="Collapsible"
					ref={this.ref}
					style={this.state.maxHeight >= 0 ? {maxHeight: this.state.maxHeight + "px"} : {opacity: 0}}
					onClick={(e) => this.handleClick(e)}
				>
					{this.props.content}
				</div>
			</div>
		);
	}
}

export default CollapsibleMenu;